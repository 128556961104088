import { THttpRequestConfig } from 'hooks/useHttpClient';

export const AUTH_TOKEN_KEY = 'auth_token';
export const REFRESH_TOKEN_KEY = 'refresh_token';

export const AUTH_API = {
	logout: (): THttpRequestConfig => ({
		url: 'crm/auth/logout',
		method: 'POST',
		data: {},
	}),
	profile: (): THttpRequestConfig => ({
		url: 'crm/profile',
		method: 'GET',
	}),
	login: (email: string, password: string): THttpRequestConfig => ({
		url: 'crm/auth/login',
		method: 'POST',
		data: {
			email,
			password,
		},
	}),
	refreshToken: (refresh_token?: string): THttpRequestConfig => ({
		url: 'crm/auth/token',
		method: 'POST',
		data: {
			refresh_token,
		},
	}),

	// EXTRA

	accessible_vendors: (): THttpRequestConfig => ({
		url: '/crm/accessible-vendors',
		method: 'GET',
		params: { per_page: 500 },
	}),
	accessible_stores: (): THttpRequestConfig => ({
		url: '/crm/accessible-stores',
		method: 'GET',
	}),
};
