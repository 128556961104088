// TEMPORARY PLACEHOLDER FOR useVersionContext.

// TODO: implement versionContext

export const useVersionContext = () => {
	const currentFeatureVariantSelection: Record<string, boolean> = {
		CRM_FILTER_BY_DATE: false,
	};

	return { currentFeatureVariantSelection };
};
