import { useContext, useEffect } from 'react';
import { SetURLSearchParams, useLocation } from 'react-router-dom';
import { THttpRequestParams } from 'hooks/useHttpClient';
import { ListParamsContext } from '../context/ListParamsContext';

export const useListParamsSetter = (groupedParams: THttpRequestParams, setParams: SetURLSearchParams) => {
	const { listParamsRef } = useContext(ListParamsContext);
	const location = useLocation();

	// ! effects
	useEffect(() => {
		if (!listParamsRef) return;

		const savedParams = listParamsRef.current[location.pathname];
		if (!savedParams) return;
		setParams(savedParams);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (!listParamsRef) return;

		listParamsRef.current[location.pathname] = groupedParams;
	}, [groupedParams, listParamsRef, location]);
};
