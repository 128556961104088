import { PropsWithChildren, createContext, memo, useContext, useState } from 'react';
import { Nullable } from 'types';
import { EBrand } from 'types/appConfig';
import { ConfigProvider, ThemeConfig } from 'antd';
import { ITheme, THEMES_CONFIG } from 'brand-config';

interface IAppBrandNameData {
	brand?: EBrand;
	brandConfig: ITheme;
}

const setFavIcon = (brandName: string = '') => {
	let link: Nullable<HTMLLinkElement> = document.querySelector("link[rel~='icon']");

	if (!link) {
		link = document.createElement('link');
		link.rel = 'icon';
		document.head.appendChild(link);
	}
	link.href = `${brandName}-favicon.ico`;
};

const useAppBrand = () => {
	return useContext(AppBrandContext);
};

const AppBrandContext = createContext<IAppBrandNameData>({} as IAppBrandNameData);

const AppBrandProvider = memo(({ brand: brandArg, children }: PropsWithChildren<{ brand: EBrand }>) => {
	const [brand] = useState<EBrand>(brandArg);

	const brandConfig = THEMES_CONFIG[brand as EBrand];

	setFavIcon(brand);

	let primaryColor = '';
	let warningColor = '';
	let textColor = '';

	if (brand) {
		primaryColor = brandConfig.primary_color;
		warningColor = brandConfig.warning_color;
		textColor = brandConfig.color_text;
	}

	const ANTD_THEME_CONFIG: ThemeConfig = {
		token: {
			colorPrimary: primaryColor,
			colorWarning: warningColor,
			fontFamily: 'Jost',
			colorInfo: primaryColor,
			colorText: textColor,
		},
	};

	const brandData = { brand, brandConfig: THEMES_CONFIG[brand as EBrand] };

	return (
		<AppBrandContext.Provider value={brandData}>
			<ConfigProvider theme={ANTD_THEME_CONFIG}>{children}</ConfigProvider>
		</AppBrandContext.Provider>
	);
});

export { AppBrandProvider, useAppBrand };
