import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCountry } from 'hooks';
import { Clock, UTC_TIME_ZONE, useLocaleTimeUtils } from 'hooks/useLocaleTimeUtils';
import dayjs from 'dayjs';
import { Select } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { ClockCircleOutlined } from '@ant-design/icons';

export const TimeZoneSelector: FC = () => {
	const { t: tCommon } = useTranslation('common');

	const { currentCountry } = useCountry();
	const { selectedTimezone, setSelectedTimeZone } = useLocaleTimeUtils();

	// ! useMemo
	const timeZoneOptions: DefaultOptionType[] = useMemo(() => {
		if (!currentCountry) return [];

		const resultList: DefaultOptionType[] = [];

		resultList.push({
			label: (
				<Clock
					timeZoneName={tCommon('time_zones.current_timezone')}
					timezone={dayjs.tz.guess()}
				/>
			),
			value: dayjs.tz.guess(),
		});

		resultList.push({
			label: <Clock timeZoneName={tCommon('time_zones.utc_timezone')} />,
			value: UTC_TIME_ZONE,
		});

		const countryTimeZoneList =
			currentCountry?.timezones.map(
				(tz): DefaultOptionType => ({ value: tz.name, label: <Clock timezone={tz.name} /> })
			) ?? [];

		resultList.push(...countryTimeZoneList);

		return resultList;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentCountry]);

	// ! render
	return (
		<Select<string>
			suffixIcon={<ClockCircleOutlined />}
			showSearch={false}
			size='middle'
			options={timeZoneOptions}
			value={selectedTimezone}
			className='w-100'
			onChange={setSelectedTimeZone}
		/>
	);
};
