import { FC, PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthenticatedInformationProvider, useAuth } from 'hooks';
import { ABSOLUTE_ROUTES } from 'configs/routes';

export const RequireAuthWrapper: FC<PropsWithChildren> = ({ children }) => {
	const location = useLocation();
	const { isAuthenticated } = useAuth();

	if (!isAuthenticated()) {
		return (
			<Navigate
				to={ABSOLUTE_ROUTES.LOGIN}
				state={{ from: location }}
				replace
			/>
		);
	}

	return <AuthenticatedInformationProvider>{children}</AuthenticatedInformationProvider>;
};
