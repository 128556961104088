import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactOptions } from 'i18next';

interface ITranslatedLabelProps {
	nameSpace?: string;
	i18nKey: string;
	extra?: Record<string, ReactNode> & ReactOptions;
}

export const TranslatedLabel: FC<ITranslatedLabelProps> = ({ nameSpace = 'common', i18nKey, extra = {} }) => {
	const { t } = useTranslation(nameSpace);
	return <span>{t(i18nKey, extra)}</span>;
};
