import { FC, useRef } from 'react';
import { useOutlet } from 'react-router-dom';
import { IListParamsRouteWrapper, IListParamsState, ITableColumnsState } from './types';
import { ListParamsContext } from './context/ListParamsContext';

const ListParamsRouteWrapper: FC<IListParamsRouteWrapper> = ({ children }) => {
	const outlet = useOutlet();

	// ! refs
	// using ref instead of state to reduce children re-renders
	const listParamsRef = useRef<IListParamsState>({});
	const tableColumnsRef = useRef<ITableColumnsState>({});

	// ! render
	return (
		<ListParamsContext.Provider value={{ listParamsRef, tableColumnsRef }}>
			{children || outlet}
		</ListParamsContext.Provider>
	);
};

export default ListParamsRouteWrapper;
