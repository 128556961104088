import { Property } from 'csstype';
import { EBrand } from 'types/appConfig';

export interface ITheme {
	// colors
	primary_color: Property.Color;
	secondary_color: Property.Color;
	warning_color: Property.Color;
	color_text: Property.Color;
}

export const THEMES_CONFIG: { [key in EBrand]: ITheme } = {
	getdukan: {
		primary_color: '#d81f51',
		secondary_color: '#fff0f1',
		warning_color: '#f7be11',
		color_text: '#3b424b',
	},
	getcari: {
		primary_color: '#cc0071',
		secondary_color: '#ffe6ef',
		warning_color: '#ffae00',
		color_text: '#3b424b',
	},
};
