import ReactDOM from 'react-dom/client';
// import i18n, needs to be bundled :)
import './i18n';
import App from './App';
import './index.css';

// DISABLE consoles outside of development
if (process.env.NODE_ENV !== 'development') {
	console.log = () => {};
	console.debug = () => {};
	console.info = () => {};
	console.warn = () => {};
	console.error = () => {};
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);
