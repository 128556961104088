import { FC, PropsWithChildren, createContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { useGlobalConfigs, useHandleErrors } from 'hooks';
import { processClientLogout } from './helpers';
import { AUTH_API, AUTH_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'configs/api';
import { ABSOLUTE_ROUTES } from 'configs/routes';
import { IUserLogin } from 'types/api';
import { Nullable, TEmptyFunction } from 'types/common';

export interface IUserAuthInfo {
	first_name: string;
	last_name: string;
	id: number;
	email: string;
	country_id: number;
	phone_number: string;
	country_code: string;

	access_token: string;
	refresh_token: string;
}

export type THasPermissionMethod = (requiredPermissions: string | string[]) => boolean;

interface IAuthContextData {
	user: Nullable<IUserAuthInfo>;
	signin: (email: string, password: string) => void;
	signout: TEmptyFunction;
	isAuthenticated: () => boolean;
	hasPermission: THasPermissionMethod;
}

export const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const { http, storage } = useGlobalConfigs();
	const { handleError } = useHandleErrors();

	const [user, setUser] = useState<Nullable<IUserAuthInfo>>(null);

	const isAuthenticated = (): boolean => {
		const token = storage.get(AUTH_TOKEN_KEY);

		return !!(token && token.length > 0);
	};

	const processUserData = async (loginInfo: IUserLogin): Promise<IUserAuthInfo> => {
		const { user, ...restLoginInfo } = loginInfo;

		storage.set(AUTH_TOKEN_KEY, loginInfo.access_token);
		storage.set(REFRESH_TOKEN_KEY, loginInfo.refresh_token);

		return {
			...restLoginInfo,
			...user,
		};
	};

	const fetchProfile = async (ctrl: AbortController) => {
		try {
			const { data } = await http({ ...AUTH_API.profile(), signal: ctrl.signal });
			const newUserInfo = await processUserData({
				user: data,
				access_token: storage.get(AUTH_TOKEN_KEY) ?? '',
				refresh_token: storage.get(REFRESH_TOKEN_KEY) ?? '',
			});

			setUser(newUserInfo);
		} catch (err) {
			processClientLogout(storage, navigate);
			handleError(err, true);
		}
	};

	const signin = async (email: string, password: string) => {
		try {
			const requestConfig = AUTH_API.login(email, password);
			// * get login info
			const loginResponse: AxiosResponse<IUserLogin> = await http(requestConfig);
			const { data: loginData } = loginResponse;

			// get main user data
			const userInfo = await processUserData(loginData);
			setUser(userInfo);

			navigate(location.state?.from?.pathname ?? ABSOLUTE_ROUTES.CAMPAIGNS, { replace: true });
		} catch (err) {
			handleError(err, true);
		}
	};

	const signout = async () => {
		try {
			await http(AUTH_API.logout());

			// Clear user auth data
			setUser(null);

			processClientLogout(storage, navigate);
		} catch (err) {
			handleError(err);
		} finally {
			setUser(null);
		}
	};

	// ! effects
	useEffect(() => {
		const ctrl = new AbortController();
		if (!user && isAuthenticated()) {
			fetchProfile(ctrl);
		}
		// Unsubscribing
		return () => ctrl.abort();
	}, [user]); // eslint-disable-line react-hooks/exhaustive-deps

	const authData: IAuthContextData = {
		user,
		signin,
		signout,
		isAuthenticated,
		hasPermission: () => true, // Temp
	};

	return <AuthContext.Provider value={authData}>{children}</AuthContext.Provider>;
};
