import { ReactNode, lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import ListParamsRouteWrapper from 'components/ListParamsRouteWrapper';
import ErrorPage from 'pages/ErrorPage';
import { Maps } from 'untitledui-js';

const ListCampaigns = lazy(() => import('pages/Campaigns'));
const CreateCampaign = lazy(() => import('pages/Campaigns/CreateCampaign'));

export interface IRoute {
	id?: string;
	index?: boolean;
	showOnNav: boolean;
	path?: string;
	icon?: ReactNode;
	translationKey?: string;
	children?: IRoute[];
	element?: ReactNode;
	defaultListParams?: { [k: string]: string };
}

export const ABSOLUTE_ROUTES = {
	LOGIN: '/login',
	CAMPAIGNS: '/campaigns',
	CREATE_CAMPAIGN: '/campaigns/new',
	// ! others
	NO_ACCESS_ROUTE: '/',
	// ERROR PAGES
	FORBIDDEN: '/403',
};

export const ICONS_SIZE = '20';

export interface IRouteObjectHandle {
	handle: {
		translationKey?: string;
		icon?: ReactNode;
		showOnNav?: boolean;
	};
}

export type TRouteObject = RouteObject & IRouteObjectHandle;

export const DEFAULT_ROUTES_CONFIG: TRouteObject[] = [
	// ! Dashboard
	{
		index: true,
		element: <Navigate to={'campaigns'} />,
		handle: {},
	},
	{
		id: 'campaigns',
		path: 'campaigns',
		element: <ListParamsRouteWrapper key='campaigns' />,
		handle: {
			translationKey: 'campaigns',
			icon: (
				<span className='anticon ant-menu-item-icon'>
					<Maps.Rocket01 size={ICONS_SIZE} />
				</span>
			),
			showOnNav: true,
		},
		children: [
			{
				index: true,
				element: <ListCampaigns />,
				handle: {
					translationKey: 'campaigns',
					icon: <Maps.Rocket01 size={ICONS_SIZE} />,
					showOnNav: false,
				},
			},
			{
				path: 'new',
				element: <CreateCampaign />,
				handle: {
					translationKey: 'create_campaign',
					showOnNav: false,
				},
			},
		],
	},

	// ! --------
	{
		path: '*',
		element: (
			<ErrorPage
				status='404'
				title="Sorry, the page you tried to visit isn't available."
				subTitle='Please, choose an available one from the navigation menu.'
			/>
		),
		handle: {
			showOnNav: false,
		},
	},
];
