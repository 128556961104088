import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { useForceRefreshData } from 'hooks';
import { Nullable, TEmptyFunction } from 'types/common';

export interface IUseIntervalTimer {
	isEnable: boolean;
	toggleRefreshEnable: Dispatch<SetStateAction<boolean>>;
	intervalUpdate: number;
	refreshingData: TEmptyFunction;
	resetTimer: TEmptyFunction;
}

export const useIntervalTimer = (ttl: number, isEnable: boolean = true): IUseIntervalTimer => {
	const refreshDataTimer = useRef<Nullable<NodeJS.Timer>>(null);
	const [isDataRefreshEnabled, setDataRefreshEnabled] = useState(isEnable);

	const { forceRefreshData, refreshingData } = useForceRefreshData();

	const clearRefreshTimersAndResetCounter = useCallback(() => {
		if (refreshDataTimer.current) {
			clearInterval(refreshDataTimer.current);
			refreshDataTimer.current = null;
		}
	}, [refreshDataTimer]);

	// * Data Refresh Timer
	useEffect(() => {
		if (isDataRefreshEnabled) {
			// Set refresh timers (refresh data every refreshTimeInSecs seconds)
			refreshDataTimer.current = setInterval(() => {
				refreshingData();
			}, ttl);
		} else {
			clearRefreshTimersAndResetCounter();
		}
		// Unsubscribing
		return () => clearRefreshTimersAndResetCounter();
	}, [isDataRefreshEnabled]); // eslint-disable-line react-hooks/exhaustive-deps

	const resetTimer = useCallback(async () => {
		await clearRefreshTimersAndResetCounter();
		refreshingData();
		refreshDataTimer.current = setInterval(() => {
			refreshingData();
		}, ttl);
	}, [clearRefreshTimersAndResetCounter, refreshingData, ttl]);

	return {
		intervalUpdate: forceRefreshData,
		toggleRefreshEnable: setDataRefreshEnabled,
		isEnable: isDataRefreshEnabled,
		refreshingData,
		resetTimer,
	};
};
