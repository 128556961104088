import { FC, useCallback, useMemo } from 'react';
import { DATE_TIME_INTL_FORMAT, transformToBEAcceptanceFormat } from 'utils/date';
import { Nullable } from 'types';
import { useIntervalTimer } from './useIntervalTimer';
import { useTimeZone } from './useTimeZone';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(localizedFormat);
dayjs.extend(isBetween);
dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);

export const UTC_TIME_ZONE = 'UTC';

export type THourRanges = '24' | '48' | '72' | '168' | 'all' | 'today';

// ! Clock
interface IClockProps {
	timeZoneName?: string;
	timezone?: string;
}
export const Clock: FC<IClockProps> = ({ timeZoneName, timezone = undefined }) => {
	const { intervalUpdate } = useIntervalTimer(1000 * 60);

	const ftime = useMemo(() => {
		const currentTime = timezone && timezone !== UTC_TIME_ZONE ? dayjs().tz(timezone) : dayjs().utc();

		return currentTime.format('HH:mm z');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [timezone, intervalUpdate]);

	const tzName = timeZoneName ?? timezone ?? UTC_TIME_ZONE;

	return (
		<>
			{tzName} - {ftime}
		</>
	);
};

// ! Component for formatting dates using useLocaleTimeUtils
export const LocalTimeDate: FC<{
	date: string | Date | dayjs.Dayjs;
	showTimezone?: boolean;
	format?: string;
}> = ({ date, showTimezone, format }) => {
	const { formatDate } = useLocaleTimeUtils();

	return <>{formatDate(date, showTimezone, format)}</>;
};

// ! HOOK LocalTimeUtils
export interface IUseLocaleTimeUtilsProps {
	selectedTimezone: string;
	setSelectedTimeZone: (newTimeZone: string) => void;

	parseDate: typeof dayjs;
	getCurrentTimeZoneDate: (date: string | Date | dayjs.Dayjs) => dayjs.Dayjs;
	formatDate: (date: string | Date | dayjs.Dayjs, showTimezone?: boolean, format?: string) => string;

	getTodayParamToFilter: () => string;
	getStartDateParamToFilter: (hoursFilter: number) => string;
	getHourFilter: (hoursFilter?: THourRanges) => Nullable<string>;
	datePickerDisablePreviousDatesFromNow: (current: dayjs.Dayjs) => boolean;
	getNowInTimeZone: () => dayjs.Dayjs;
}

export const useLocaleTimeUtils = (): IUseLocaleTimeUtilsProps => {
	const { selectedTimezone, setSelectedTimeZone } = useTimeZone();

	const getCurrentTimeZoneDate = useCallback(
		(date: string | Date | dayjs.Dayjs) => {
			let dateProcess = dayjs(date).utc();

			if (selectedTimezone !== UTC_TIME_ZONE) {
				dateProcess = dateProcess.tz(selectedTimezone);
			}

			return dateProcess;
		},
		[selectedTimezone]
	);

	const formatDate: IUseLocaleTimeUtilsProps['formatDate'] = useCallback(
		(date, showTimezone, format = DATE_TIME_INTL_FORMAT) => {
			const processDate = getCurrentTimeZoneDate(date);

			const tzDate = processDate.format(format);

			// build string
			if (showTimezone) {
				return `${tzDate} (${selectedTimezone})`;
			}
			return tzDate;
		},
		[getCurrentTimeZoneDate, selectedTimezone]
	);

	const getNowInTimeZone = () => dayjs().tz(selectedTimezone, true);

	const getTodayParamToFilter = () => transformToBEAcceptanceFormat(getNowInTimeZone().startOf('day'));

	const getStartDateParamToFilter = (hoursFilter: number): string =>
		transformToBEAcceptanceFormat(dayjs().subtract(hoursFilter, 'hour'));

	const getHourFilter = (hoursFilter?: THourRanges) => {
		if (hoursFilter) {
			switch (hoursFilter) {
				case 'today': {
					return getTodayParamToFilter();
				}
				case 'all': {
					break;
				}
				default: {
					return getStartDateParamToFilter(+hoursFilter);
				}
			}
		}

		return null;
	};

	const datePickerDisablePreviousDatesFromNow = (current: dayjs.Dayjs) =>
		!!current && current < getNowInTimeZone().startOf('day');

	// ! return
	const api: IUseLocaleTimeUtilsProps = {
		selectedTimezone,
		setSelectedTimeZone,

		parseDate: dayjs,
		getCurrentTimeZoneDate,
		formatDate,

		// ! Others
		getTodayParamToFilter,
		getStartDateParamToFilter,

		getHourFilter,
		datePickerDisablePreviousDatesFromNow,
		getNowInTimeZone,
	};

	return api;
};
