import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useAuth, useGlobalConfigs, useHandleErrors } from 'hooks';
import { COUNTRIES_API } from 'configs/api';
import { ICountry } from 'types/api';
import { IListResponse } from 'types/common';
import Spinner from 'components/Spinner';

interface ICountryContextData {
	currency: string;
	currentCountry: ICountry;
	countryList: ICountry[];
}

const useCountry = () => {
	return useContext(CountryContext);
};

const CountryContext = createContext<ICountryContextData>({} as ICountryContextData);

const CountryProvider = ({ children }: PropsWithChildren) => {
	const auth = useAuth();

	const [countryList, setCountryList] = useState<ICountry[]>([]);
	const [currentCountry, setCurrentCountry] = useState<undefined | ICountry>();

	const { http } = useGlobalConfigs();
	const { handleError } = useHandleErrors();

	useEffect(() => {
		const fetchCountryList = async () => {
			try {
				const countryListResponse: AxiosResponse<IListResponse<ICountry>> = await http(COUNTRIES_API.list());
				setCountryList(countryListResponse.data.data);
			} catch (err) {
				handleError(err);
			}
		};

		fetchCountryList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (auth.user && countryList.length) {
			const newCountry = countryList.find((possibleCountry) => possibleCountry.id === auth.user?.country_id);
			setCurrentCountry(newCountry);
		}
	}, [auth.user, countryList]);

	if (!currentCountry) return <Spinner />;

	const settingsData: ICountryContextData = {
		currency: currentCountry.currency_code,
		countryList,
		currentCountry,
	};

	return <CountryContext.Provider value={settingsData}>{children}</CountryContext.Provider>;
};

export { useCountry, CountryProvider };
