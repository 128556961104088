import { NavigateFunction } from 'react-router-dom';
import { IStorageUtils } from 'utils/storage';
import { AUTH_TOKEN_KEY } from 'configs/api';
import { ABSOLUTE_ROUTES } from 'configs/routes';

export const isAuthenticated = (storage: IStorageUtils): boolean => {
	const token = storage.get(AUTH_TOKEN_KEY);

	return !!(token && token.length > 0);
};

export const processClientLogout = (storage: IStorageUtils, navigate: NavigateFunction) => {
	storage.clear();

	// Go to Login page
	navigate(ABSOLUTE_ROUTES.LOGIN, {});
};
