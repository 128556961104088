import { Suspense, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { useLanguageSettings } from 'hooks';
import { getNavItems } from './helper';
import { SUPPORTED_LANGUAGES } from 'configs/common';
import { DEFAULT_ROUTES_CONFIG } from 'configs/routes';
import Nav from 'components/Nav';
import Spinner from 'components/Spinner';
import styles from './Layout.module.css';
import { Layout as AntLayout, ConfigProvider } from 'antd';
import arEG from 'antd/es/locale/ar_EG';
import enUS from 'antd/es/locale/en_US';

const { Content } = AntLayout;

export const Layout = memo(() => {
	const { selectedLanguage } = useLanguageSettings();
	const navItems = useMemo(() => getNavItems(DEFAULT_ROUTES_CONFIG), []);

	const { t: tCommon } = useTranslation('common');
	const formValidateMessages = {
		required: tCommon('form_validations.required_field'),
	};

	// ! render
	return (
		<ConfigProvider
			direction={SUPPORTED_LANGUAGES[selectedLanguage].defaultDirection}
			locale={SUPPORTED_LANGUAGES[selectedLanguage].iso_two_code === 'ar' ? arEG : enUS}
			form={{ validateMessages: formValidateMessages }}
		>
			<AntLayout className={styles.main_container}>
				<Nav items={navItems} />

				<Content className={styles.dynamic_content}>
					<Suspense fallback={<Spinner />}>
						<Outlet />
					</Suspense>
				</Content>
			</AntLayout>
		</ConfigProvider>
	);
});
