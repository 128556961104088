import { NavigateFunction } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { HTTP_STATUS_CODE } from 'configs/api';
import { IStorageUtils } from './storage';
import { AppError } from 'exceptions/AppError';
import { processClientLogout } from 'contexts/auth/helpers';
import { MessageInstance } from 'antd/es/message/interface';

// ? TEMP SOLUTION ------
const DEFAULT_ERROR_MESSAGE = 'Sorry, something went wrong!';
// ? --------------------

export type THandleError = (error: AxiosResponse | AppError, displayErrorMsg?: boolean) => void;

export const handleError =
	(storage: IStorageUtils, navigate: NavigateFunction, message: MessageInstance): THandleError =>
	(error: AxiosResponse | AppError, displayErrorMsg?: boolean) => {
		const status = error?.status;
		const errorMessage: string = error?.data?.message ?? DEFAULT_ERROR_MESSAGE;

		const isClientAppError = AppError.isError(error);
		const isUnauthorized = status === HTTP_STATUS_CODE.UNAUTHORIZED;

		// Access and refresh tokens were expired and this is not custom error
		if (isClientAppError && isUnauthorized) {
			processClientLogout(storage, navigate);

			message.error(errorMessage);
			return;
		}

		if (displayErrorMsg) message.error(errorMessage, 5);

		console.warn('There was an error!', error);
	};
