import { useState } from 'react';
import { AxiosResponse } from 'axios';
import { useGlobalConfigs, useHandleErrors } from 'hooks';
import { Nullable } from 'types/common';
import { IFetchDataConfig, TError } from './types';

function useHttpClient<IDataType>() {
	const { http } = useGlobalConfigs();
	const { handleError } = useHandleErrors();

	const [response, setResponse] = useState<Nullable<IDataType>>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<Nullable<TError>>(null);

	const request = async (config: IFetchDataConfig<IDataType>, signal?: AbortSignal): Promise<void> => {
		const { requestConfig, successCallback, errorCallback, finallyCallback, displayErrorMsg = true } = config;

		setIsLoading(true);
		setError(null);

		return http({ ...requestConfig, signal })
			.then((result: AxiosResponse<IDataType>) => {
				setError(null);
				setResponse(result.data);
				successCallback?.(result.data);
			})
			.catch((error) => {
				setError(error);
				errorCallback?.(error);
				handleError(error, !error.__CANCEL__ && displayErrorMsg);
			})
			.finally(() => {
				setIsLoading(false);
				finallyCallback?.();
			});
	};

	return {
		response,
		isLoading,
		error,
		request,
	};
}

export { useHttpClient };
