import { FC } from 'react';
import styles from './CustomTooltip.module.css';
import { Tooltip, TooltipProps } from 'antd';

export const CustomTooltip: FC<TooltipProps> = ({ children, ...props }) => {
	return (
		<div className={styles.custom_tooltip}>
			<Tooltip
				color='white'
				overlayInnerStyle={{ color: 'var(--grey-900)' }}
				{...props}
			>
				{children}
			</Tooltip>
		</div>
	);
};

export default CustomTooltip;
