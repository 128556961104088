import axios from 'axios';
import { AxiosInstance } from 'axios';
import qs from 'qs';
import { IStorageUtils } from 'utils/storage';
import { AUTH_API, AUTH_TOKEN_KEY, HTTP_STATUS_CODE, REFRESH_TOKEN_KEY } from 'configs/api';
import { APP_LANGUAGE_STORAGE_KEY, COUNTRY_ID_STORAGE_KEY } from 'configs/common';
import { AppError } from 'exceptions/AppError';

export type THttpFunction = AxiosInstance;

// Create axios instance
export const http = (appConfig_api_url: string, storageUtils: IStorageUtils): AxiosInstance => {
	const axiosInstance: AxiosInstance = axios.create({
		baseURL: appConfig_api_url,
		headers: {
			'Content-Type': 'application/json',
		},
		paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
	});

	// Set the interceptors
	axiosInstance.interceptors.request.use(
		(config) => {
			const token = storageUtils.get(AUTH_TOKEN_KEY);

			const accept_language =
				config?.headers?.accept_language ?? localStorage.getItem(APP_LANGUAGE_STORAGE_KEY) ?? 'en';
			if (token?.length) {
				config.headers.Authorization = `Bearer ${token}`;
				config.headers['accept-language'] = accept_language;
			}

			const country_id = storageUtils.get(COUNTRY_ID_STORAGE_KEY);
			if (country_id?.length) {
				config.params = { ...config.params, country_id };
			}

			return config;
		},
		(error) => Promise.reject(error)
	);

	// ! UNAUTHORIZED INTERCEPTOR
	axiosInstance.interceptors.response.use(
		(response) => response,
		async (error) => {
			const originalConfig = error.config;

			if (originalConfig?.url !== AUTH_API.refreshToken().url) {
				// ACCESS TOKEN WAS EXPIRED
				if (error.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED && !originalConfig._retry) {
					originalConfig._retry = true;
					const refreshToken = storageUtils.get(REFRESH_TOKEN_KEY);
					if (refreshToken && refreshToken?.length > 0) {
						try {
							const { data } = await axiosInstance(AUTH_API.refreshToken(refreshToken));

							storageUtils.set(AUTH_TOKEN_KEY, data.access_token);
							storageUtils.set(REFRESH_TOKEN_KEY, data.refresh_token);
							return axiosInstance(originalConfig);
						} catch (_error) {
							// REFRESH TOKEN FAILED
							return Promise.reject(
								new AppError(
									'Your session has expired. Please Signin again.',
									HTTP_STATUS_CODE.UNAUTHORIZED
								)
							);
						}
					}
				}
			}
			return Promise.reject(error.response ?? error);
		}
	);

	return axiosInstance;
};
