import { FC, memo } from 'react';
import { IErrorPageProps } from './types';
import { DEFAULT_STATUS, DEFAULT_TITLE } from './config';
import { Result } from 'antd';

export const ErrorPage: FC<IErrorPageProps> = memo(({ title, subTitle, status }) => (
	<Result
		status={status ?? DEFAULT_STATUS}
		title={title ?? DEFAULT_TITLE}
		subTitle={subTitle}
	/>
));
