import { FC } from 'react';
import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { CountryProvider, GlobalConfigProvider, LanguageSettingsProvider, TimeZoneProvider } from 'hooks';
import { DEFAULT_ROUTES_CONFIG } from 'configs/routes';
import { AuthProvider } from 'contexts/auth';
import { RequireAuthWrapper } from 'appWrappers';
import Spinner from 'components/Spinner';
import Layout from 'pages/Layout';
import Login from 'pages/Login';
import './App.css';
import { App as AntApp } from 'antd';

const ROUTES_CONFIG = [
	{
		element: (
			<AuthProvider>
				<Outlet />
			</AuthProvider>
		),
		children: [
			{
				path: 'login',
				element: <Login />,
				children: [],
			},
			{ path: 'wip', element: 'wip' },
			{
				path: '*',
				element: (
					<RequireAuthWrapper>
						<TimeZoneProvider>
							<CountryProvider>
								<LanguageSettingsProvider>
									<Layout />
								</LanguageSettingsProvider>
							</CountryProvider>
						</TimeZoneProvider>
					</RequireAuthWrapper>
				),
				children: DEFAULT_ROUTES_CONFIG,
			},
		],
	},
];

const APP_ROUTER = createBrowserRouter(ROUTES_CONFIG);

const App: FC = () => {
	return (
		<GlobalConfigProvider>
			<AntApp>
				<RouterProvider
					router={APP_ROUTER}
					fallbackElement={
						<Spinner
							defaultAntdSpinner
							hasOverlay={false}
						/>
					}
				/>
			</AntApp>
		</GlobalConfigProvider>
	);
};

export default App;
