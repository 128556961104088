import { PropsWithChildren, createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuthenticatedInformation } from 'hooks';
import { APP_LANGUAGE_STORAGE_KEY, SUPPORTED_LANGUAGES } from 'configs/common';
import { ABSOLUTE_ROUTES } from 'configs/routes';
import { TSupportedLanguages } from 'types/common';

interface ILanguageSettingsContextData {
	selectedLanguage: TSupportedLanguages;
	isRTL: boolean;
	handleLanguageChange: (newLang: TSupportedLanguages) => void;
}

const useLanguageSettings = () => {
	return useContext(LanguageSettingsContext);
};

const LanguageSettingsContext = createContext<ILanguageSettingsContextData>({} as ILanguageSettingsContextData);

const LanguageSettingsProvider = ({ children }: PropsWithChildren) => {
	const { i18n } = useTranslation();
	const navigate = useNavigate();

	const { handleFetchAccessibleEntities } = useAuthenticatedInformation();

	const [selectedLanguage, setSelectedLanguage] = useState<TSupportedLanguages>(
		(localStorage.getItem('i18nextLng') as TSupportedLanguages) ?? 'en'
	);

	const handleLanguageChange = (newLang: TSupportedLanguages) => {
		localStorage.setItem(APP_LANGUAGE_STORAGE_KEY, newLang);
		i18n.changeLanguage(newLang);
		setSelectedLanguage(newLang);

		handleFetchAccessibleEntities(); // TODO: @tlsltaken TEMPORARY FIX

		// if changes, reload page navigating to the Campaigns screen
		navigate(ABSOLUTE_ROUTES.CAMPAIGNS);
	};

	const settingsData = {
		selectedLanguage,
		isRTL: SUPPORTED_LANGUAGES[selectedLanguage].defaultDirection === 'rtl',
		handleLanguageChange,
	};

	return <LanguageSettingsContext.Provider value={settingsData}>{children}</LanguageSettingsContext.Provider>;
};

export { useLanguageSettings, LanguageSettingsProvider };
