import { THttpRequestConfig } from 'hooks/useHttpClient';

export const COUNTRIES_API = {
	list: (): THttpRequestConfig => ({
		url: `/countries`,
		method: 'GET',
	}),
	get: (isoTwoCode: string): THttpRequestConfig => ({
		url: `/countries/${isoTwoCode}`,
		method: 'GET',
	}),
};
