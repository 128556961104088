import { Dispatch, PropsWithChildren, SetStateAction, createContext, useContext, useState } from 'react';
import Spinner from 'components/Spinner';
import dayjs from 'dayjs';

export interface ITimeZoneContextData {
	selectedTimezone: string;
	setSelectedTimeZone: Dispatch<SetStateAction<string>>;
}

const useTimeZone = () => {
	return useContext(context);
};

const context = createContext<ITimeZoneContextData>({} as ITimeZoneContextData);

export const TimeZoneProvider = ({ children }: PropsWithChildren) => {
	const [selectedTimezone, setSelectedTimeZone] = useState(dayjs.tz.guess());

	if (!selectedTimezone) return <Spinner defaultAntdSpinner />;

	const settingsData: ITimeZoneContextData = {
		selectedTimezone,
		setSelectedTimeZone,
	};

	return <context.Provider value={settingsData}>{children}</context.Provider>;
};

export { useTimeZone };
