import { FC, memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthenticatedInformation } from 'hooks';
import { ICONS_SIZE } from 'configs/routes';
import CustomTooltip from 'components/CustomTooltip';
import styles from './VendorSelectDrawer.module.css';
import { Button, Col, Dropdown, Row } from 'antd';
import { Arrow, General } from 'untitledui-js';
import { GitBranch02 } from 'untitledui-js/icons/development';

export const VendorSelectDrawer: FC = memo(() => {
	const { t: tVendorSelector } = useTranslation('vendor-selector-drawer');
	const [openDrawer, setOpenDrawer] = useState(false);

	const { accessibleVendorIdList, selectedVendorIdList, setSelectedVendorIdList } = useAuthenticatedInformation();

	const selectedVendor = useMemo(
		() => accessibleVendorIdList?.find((v) => selectedVendorIdList.includes(v.id)),
		[accessibleVendorIdList, selectedVendorIdList]
	);

	if (!accessibleVendorIdList.length) {
		return null;
	}

	// ! handlers
	const handleClose = () => {
		if (selectedVendorIdList.length) {
			setOpenDrawer(false);
		}
	};

	const handleToggleOpen = () => setOpenDrawer((prev) => !prev);

	const handleChangeVendor = (key: React.Key) => {
		setSelectedVendorIdList([+key]);
		handleClose();
	};

	// ! Render
	return (
		<CustomTooltip title={tVendorSelector('cta.open.tooltip')}>
			<Dropdown
				open={openDrawer}
				menu={{
					items: accessibleVendorIdList.map((vendor) => {
						const isSelected = selectedVendor?.id === vendor?.id;

						return {
							className: isSelected ? styles.is_selected : '',
							key: vendor.id,
							label: (
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
									}}
								>
									<div>
										{tVendorSelector('cta.open.selected', {
											count: 1,
											id: vendor?.id,
											name: vendor?.name,
										})}
									</div>
									{isSelected && <General.Check size={ICONS_SIZE} />}
								</div>
							),
						};
					}),
					onClick: ({ key }) => handleChangeVendor(key),
				}}
			>
				<Button
					className={styles.vendor_select}
					size='large'
					onClick={handleToggleOpen}
				>
					<Row
						align='middle'
						justify='center'
						wrap={false}
						className='overflow-hidden'
					>
						<Col className='flex'>
							<GitBranch02 size={ICONS_SIZE} />
						</Col>
						<Col flex='auto'>
							{tVendorSelector('cta.open.selected', {
								count: selectedVendorIdList.length,
								id: selectedVendor?.id,
								name: selectedVendor?.name,
							})}
						</Col>

						<Col className='flex'>
							<Arrow.ChevronDown size={ICONS_SIZE} />
						</Col>
					</Row>
				</Button>
			</Dropdown>
		</CustomTooltip>
	);
});
