import { FC, PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth, useLogos } from 'hooks';
import { ABSOLUTE_ROUTES } from 'configs/routes';
import styles from './Login.module.css';
import { App, Button, Col, Form, Input, Row, Typography } from 'antd';
import loginSideImage from 'assets/images/login.svg';

const { Paragraph, Link } = Typography;

interface ILoginFormFields {
	email: string;
	password: string;
}

export const LoginLayout: FC<PropsWithChildren> = ({ children }) => {
	const { fullLogo } = useLogos();
	const currentYear = new Date().getFullYear();

	// ! render
	return (
		<div className={styles.layout}>
			<div className={styles.logo_container}>
				<img
					alt='logo'
					loading='lazy'
					src={fullLogo}
					className={styles.logo}
				/>
			</div>
			<div className={styles.content_container}>{children}</div>
			<div className={styles.footer_container}>
				<div className={styles.copyright}>
					Copyright © {currentYear} Cari General Trading Company. All rights reserved.
				</div>
				<div className={styles.footer_logo_container} />
				<div className={styles.footer_background_container} />
			</div>
		</div>
	);
};

const LoginPage: FC = () => {
	const auth = useAuth();
	const { message } = App.useApp();

	const handleLogin = ({ email = '', password = '' }: ILoginFormFields) => {
		auth.signin(email, password);
	};

	if (auth.isAuthenticated()) {
		return <Navigate to={ABSOLUTE_ROUTES.CAMPAIGNS} />;
	}

	return (
		<LoginLayout>
			<Row
				wrap={false}
				align='middle'
				gutter={[124, 124]}
			>
				<Col
					xs={0}
					sm={0}
					md={0}
					lg={12}
				>
					<div className='flex flex-center'>
						<img
							src={loginSideImage}
							alt='login-icon'
							width={400}
							className={styles.img}
						/>
					</div>
				</Col>
				<Col>
					<div className={styles.form_container}>
						<Form
							colon={false}
							layout='vertical'
							onFinish={handleLogin}
						>
							<h2 className={styles.title}>Welcome Back!</h2>
							<Paragraph className={styles.subtitle}>
								Sign in to your Cari CRM dashboard using your credentials.
							</Paragraph>
							<Form.Item
								label='Email'
								name='email'
								rules={[
									{ required: true, message: 'Please input your email.' },
									{ type: 'email', message: 'Please input a valid email.' },
								]}
							>
								<Input
									size='large'
									autoComplete='off'
									placeholder='Email'
								/>
							</Form.Item>
							<Form.Item
								label='Password'
								name='password'
								rules={[{ required: true, message: 'Please input your password.' }]}
							>
								<Input
									size='large'
									type='password'
									autoComplete='off'
									placeholder='Password'
								/>
							</Form.Item>
							<Link
								className={styles.forgot_password}
								onClick={() => {
									message.warning('Contact the operations team to reset your password', 5);
								}}
							>
								Forgot password?
							</Link>
							<Button
								className={styles.submit_btn}
								type='primary'
								htmlType='submit'
								block
							>
								Login
							</Button>
						</Form>
					</div>
				</Col>
			</Row>
		</LoginLayout>
	);
};

export default LoginPage;
