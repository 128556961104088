import { FC, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { ESupportedCountryCodes, SUPPORTED_LANGUAGES } from 'configs/common';
import { ICONS_SIZE, IRoute } from 'configs/routes';
import { TSupportedLanguages } from 'types/common';
import TranslatedLabel from 'components/TranslatedLabel';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { Maps } from 'untitledui-js';

const NavLinkLabelWrapper: FC<{ route: IRoute; children: ReactNode }> = ({
	route: { path, defaultListParams },
	children,
}): JSX.Element => {
	const finalPath = defaultListParams ? `${path}?${new URLSearchParams(defaultListParams)}` : path!;

	return <NavLink to={finalPath}>{children}</NavLink>;
};

export const convertIRouteToMenuItemType = (route: IRoute, index: number): ItemType => {
	const { path, translationKey, children, icon } = route;

	const ContentLabel = () => <TranslatedLabel i18nKey={`nav_items.${translationKey}`} />;

	const menuKey = path ?? translationKey ?? `nav_item_${index}`;

	const item: ItemType = {
		key: menuKey.replaceAll('-', '_'),
		icon,
		label: (
			<span
				className='ant-menu-title-content'
				style={{ textTransform: 'capitalize' }}
			>
				{path ? (
					<NavLinkLabelWrapper route={route}>
						<ContentLabel />
					</NavLinkLabelWrapper>
				) : (
					<ContentLabel />
				)}
			</span>
		),
		children: children?.map(convertIRouteToMenuItemType),
	};

	return item;
};

export const getLanguageMenu = (
	currentLanguage: TSupportedLanguages,
	handleLanguageChange: (newLang: TSupportedLanguages) => void
): ItemType => {
	const newLanguageConfig =
		currentLanguage === ESupportedCountryCodes.ENGLISH
			? SUPPORTED_LANGUAGES[ESupportedCountryCodes.ARABIC]
			: SUPPORTED_LANGUAGES[ESupportedCountryCodes.ENGLISH];

	return {
		key: 'change_language_cta',
		icon: <Maps.Globe01 size={ICONS_SIZE} />,
		label: <TranslatedLabel i18nKey={`supported_languages.${newLanguageConfig.iso_two_code}`} />,
		onClick: () => {
			handleLanguageChange(newLanguageConfig.iso_two_code);
		},
	};
};
