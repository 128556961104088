export enum EBrand {
	DUKAN = 'getdukan',
	CARI = 'getcari',
}

export enum EGlobalConfigFields {
	API_URL = 'api_url',
	BRAND = 'brand',
}

export interface IGlobalConfig {
	[EGlobalConfigFields.API_URL]: string;
	[EGlobalConfigFields.BRAND]: EBrand;
}
