export const HTTP_STATUS_CODE = {
	OK: 200,
	BAD_REQUEST: 400,
	UNAUTHORIZED: 401,
	FORBIDDEN: 403,
	NOT_FOUND: 404,
	LOCKED: 423,
	SERVER_ERROR: 500,
};

export * from './auth';
export * from './countries';
export * from './promotions';
