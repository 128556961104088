import { FC, memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatches } from 'react-router-dom';
import { useAuth, useAuthenticatedInformation, useLanguageSettings, useLogos } from 'hooks';
import { convertIRouteToMenuItemType, getLanguageMenu } from './helper';
import { ICONS_SIZE, TRouteObject } from 'configs/routes';
import { INavProps } from './types';
import { TimeZoneSelector } from 'components/TimeZoneSelector/TimeZoneSelector';
import styles from './Nav.module.css';
import { VendorSelectDrawer } from './VendorSelectDrawer/VendorSelectDrawer';
import { Layout as AntLayout, Button, Menu, Space } from 'antd';
import { General } from 'untitledui-js';
import { ChevronLeft } from 'untitledui-js/icons/arrow';

export const Nav: FC<INavProps> = memo(({ items = [] }) => {
	const { t: tNavItems } = useTranslation('common');
	const matches = useMatches();
	const auth = useAuth();
	const { isRTL } = useLanguageSettings();
	const { accessibleVendorIdList } = useAuthenticatedInformation();
	const { selectedLanguage, handleLanguageChange } = useLanguageSettings();

	const { fullLogo, miniLogo } = useLogos();

	const [isCollapsed, setIsCollapsed] = useState(false);
	const logo = useMemo(() => (isCollapsed ? miniLogo : fullLogo), [fullLogo, isCollapsed, miniLogo]);

	// ! handlers
	const handleCollapseToggle = () => setIsCollapsed((prev) => !prev);

	const selectedNavItems = useMemo(() => {
		const res = matches;

		return res.map((route) => (route as TRouteObject)?.handle?.translationKey).filter((a) => a);
	}, [matches]);

	const navItems = useMemo(() => items.map(convertIRouteToMenuItemType), [items]);

	// ! render
	return (
		<AntLayout.Sider
			width={300}
			className={styles.slider}
			theme='light'
			collapsible
			collapsed={isCollapsed}
			trigger={null}
		>
			<Button
				size='large'
				shape='circle'
				className={`${styles.custom_slider_trigger} ${isRTL ? 'rtl' : 'ltr'} `}
				onClick={handleCollapseToggle}
				icon={
					<span
						role='img'
						aria-label='plus'
						className={`anticon anticon-plus ${
							isRTL ? (isCollapsed ? '' : 'rotate') : !isCollapsed ? '' : 'rotate'
						}`}
					>
						<ChevronLeft />
					</span>
				}
			/>

			<div className={styles.nav_slider}>
				<img
					alt='logo'
					src={logo}
					className={styles.app_logo}
				/>

				<div className={styles.nav_selectores_container}>
					<Space
						direction='vertical'
						className='w-100'
					>
						<TimeZoneSelector />
						{!!accessibleVendorIdList.length && <VendorSelectDrawer />}
					</Space>
				</div>

				<Menu
					mode='inline'
					theme='light'
					items={navItems}
					className={`${styles.top_menu} ${isRTL ? 'rtl' : 'ltr'}`}
					selectedKeys={selectedNavItems}
				/>

				<Menu
					mode='inline'
					className={styles.bottom_menu}
					selectable={false}
					items={[
						getLanguageMenu(selectedLanguage, handleLanguageChange),
						{
							key: 'logout_cta',
							label: tNavItems('nav_items.logout'),
							icon: <General.Logout01 size={ICONS_SIZE} />,
							onClick: () => auth.signout(),
						},
					]}
				/>
			</div>
		</AntLayout.Sider>
	);
});
