import { useNavigate } from 'react-router-dom';
import { useGlobalConfigs } from 'hooks';
import { handleError } from 'utils/handleError';
import { App } from 'antd';

const useHandleErrors = () => {
	const navigate = useNavigate();
	const { storage } = useGlobalConfigs();
	const { message } = App.useApp();

	return { handleError: handleError(storage, navigate, message) };
};

export { useHandleErrors };
