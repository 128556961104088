import { Dispatch, FC, PropsWithChildren, SetStateAction, createContext, useContext, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useAuth, useGlobalConfigs, useHandleErrors } from 'hooks';
import { AUTH_API } from 'configs/api';
import { IListResponse, IVendorUserAssociatedStore, IVendorUserAssociatedVendor } from 'types';
import { AppError } from 'exceptions/AppError';
import { IUserAuthInfo } from 'contexts/auth';
import Spinner from 'components/Spinner';

interface IAuthContextData {
	user: IUserAuthInfo;
	accessibleVendorIdList: IVendorUserAssociatedVendor[];
	accessibleStoreIdList: IVendorUserAssociatedStore[];
	selectedVendorIdList: number[];
	setSelectedVendorIdList: Dispatch<SetStateAction<number[]>>;
	handleFetchAccessibleEntities: () => Promise<void>;
}

const AuthenticatedInformationContext = createContext<IAuthContextData>({} as IAuthContextData);

const useAuthenticatedInformation = () => {
	return useContext(AuthenticatedInformationContext);
};

const AuthenticatedInformationProvider: FC<PropsWithChildren> = ({ children }) => {
	const auth = useAuth();
	const { http } = useGlobalConfigs();
	const { handleError } = useHandleErrors();

	const [accessibleVendorIdList, setAccessibleVendorIdList] = useState<IVendorUserAssociatedVendor[]>([]);
	const [accessibleStoreIdList, setAccessibleStoreIdList] = useState<IVendorUserAssociatedStore[]>([]);

	const [selectedVendorIdList, setSelectedVendorIdList] = useState<number[]>([]);

	const handleFetchAccessibleEntities = async () => {
		try {
			const accessibleVendorsResponse: AxiosResponse<IListResponse<IVendorUserAssociatedVendor>> = await http(
				AUTH_API.accessible_vendors()
			);

			setAccessibleVendorIdList(accessibleVendorsResponse.data.data);

			const accessibleStoresResponse: AxiosResponse<IListResponse<IVendorUserAssociatedStore>> = await http(
				AUTH_API.accessible_stores()
			);
			setAccessibleStoreIdList(accessibleStoresResponse.data.data);

			// TEMP SELECT FIRST VENDOR
			setSelectedVendorIdList(
				accessibleVendorsResponse.data.data.length ? [accessibleVendorsResponse.data.data[0].id] : []
			);
		} catch (err) {
			handleError(new AppError(err));
		}
	};

	useEffect(() => {
		handleFetchAccessibleEntities();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	if (!auth.user) return <Spinner />;

	const authData = {
		user: auth.user,
		accessibleVendorIdList,
		accessibleStoreIdList,

		// state
		handleFetchAccessibleEntities,
		selectedVendorIdList,
		setSelectedVendorIdList,
	};

	return (
		<AuthenticatedInformationContext.Provider value={authData}>{children}</AuthenticatedInformationContext.Provider>
	);
};

export { useAuthenticatedInformation, AuthenticatedInformationProvider };
