import { CSSProperties } from 'react';
import { ISupportedLanguage } from 'types/common';
import { DescriptionsProps } from 'antd';

export const APP_LANGUAGE_STORAGE_KEY = 'selected_app_language';
export const COUNTRY_ID_STORAGE_KEY = 'selected_country_id';

export const DEFAULT_EMPTY_VALUE_PLACEHOLDER = '--';

export enum ESupportedCountryCodes {
	ENGLISH = 'en',
	ARABIC = 'ar',
}

export const SUPPORTED_LANGUAGES: Record<ESupportedCountryCodes, ISupportedLanguage> = {
	[ESupportedCountryCodes.ENGLISH]: {
		name: 'English',
		iso_two_code: 'en',
		defaultDirection: 'ltr',
	},
	[ESupportedCountryCodes.ARABIC]: {
		name: 'Arabic',
		iso_two_code: 'ar',
		defaultDirection: 'rtl',
	},
};

export const DEFAULT_TAG_STYLES: CSSProperties = {
	userSelect: 'none',
	borderRadius: '20px',
	display: 'inline-flex',
	alignItems: 'center',
};

// ! descriptions
export const DEFAULT_DESCRIPTIONS_PROPS: DescriptionsProps = {
	column: 1,
	size: 'small',
	labelStyle: {
		fontWeight: '600',
		alignSelf: 'baseline',
	} as CSSProperties,
};
