import { PropsWithChildren, createContext, useContext, useLayoutEffect, useState } from 'react';
import { THttpFunction, http } from 'utils/axiosInstance';
import { IStorageUtils, storageUtils } from 'utils/storage';
import { EBrand, EGlobalConfigFields, IGlobalConfig } from 'types/appConfig';
import Spinner from 'components/Spinner';
import { AppBrandProvider } from './useAppBrandName';
import * as yup from 'yup';

const getConfiguration = async (): Promise<object> => {
	const response = await fetch('/config.json', {
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
		},
	});
	return response.json();
};

interface IGlobalConfigData {
	configs: IGlobalConfig;

	// utils
	http: THttpFunction;
	storage: IStorageUtils;
}

const useGlobalConfigs = () => {
	return useContext(GlobalConfigContext);
};

const GlobalConfigContext = createContext<IGlobalConfigData>({} as IGlobalConfigData);

const GlobalConfigProvider = ({ children }: PropsWithChildren) => {
	const [configs, setConfigs] = useState<IGlobalConfig>();

	useLayoutEffect(() => {
		getConfiguration().then((config: object) => {
			const schema: yup.ObjectSchema<IGlobalConfig> = yup.object({
				[EGlobalConfigFields.BRAND]: yup.string().oneOf(Object.values(EBrand)).required(),
				[EGlobalConfigFields.API_URL]: yup.string().required(),
			});

			try {
				const validatedConfig = schema.validateSync(config);

				setConfigs(validatedConfig);
			} catch (error) {
				console.error(
					"Global configuration json file is not valid! Verify either 'runtime-config.json or public/config.json'"
				);
				console.error(error);
				throw error;
			}
		});
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	if (!configs) {
		return <Spinner />;
	}

	const storage = storageUtils(configs.brand);

	const data = {
		configs,

		http: http(configs.api_url, storage),
		storage: storageUtils(configs.brand),
	};

	return (
		<GlobalConfigContext.Provider value={data}>
			<AppBrandProvider brand={configs.brand}>{children}</AppBrandProvider>
		</GlobalConfigContext.Provider>
	);
};

export { useGlobalConfigs, GlobalConfigProvider };
