import { THttpRequestConfig } from 'hooks/useHttpClient';
import { ECampaignFormFields, EPromotionType, IPromotionCreatePayloadTemp } from 'types/api';

export const PROMOTIONS_API = {
	list: (
		vendors_id: number | number[],
		type: EPromotionType,
		searchParams?: Partial<Record<ECampaignFormFields, undefined | string>>
	): THttpRequestConfig => ({
		url: `/crm/promotions`,
		method: 'GET',
		params: { type, vendors_id, ...searchParams },
	}),
	// ! CRUD
	create: (payload: IPromotionCreatePayloadTemp): THttpRequestConfig => ({
		url: `/crm/promotions`,
		method: 'POST',
		data: payload,
	}),
	// ! Other
	generatePromoCode: (): THttpRequestConfig => ({ url: `/crm/promotions/generate-code`, method: 'POST' }),
	// operations
	launch: (id: number): THttpRequestConfig => ({
		url: `/crm/promotions/${id}/launch`,
		method: 'POST',
	}),
	push: (id: number): THttpRequestConfig => ({ url: `/crm/promotions/${id}/push`, method: 'POST' }),
	pause: (id: number): THttpRequestConfig => ({
		url: `/crm/promotions/${id}/pause`,
		method: 'POST',
	}),
	resume: (id: number): THttpRequestConfig => ({ url: `/crm/promotions/${id}/resume`, method: 'POST' }),
	stop: (id: number): THttpRequestConfig => ({ url: `/crm/promotions/${id}/stop`, method: 'POST' }),
	reactivate: (id: number): THttpRequestConfig => ({
		url: `/crm/promotions/${id}/reactivate`,
		method: 'POST',
	}),
	revoke: (id: number): THttpRequestConfig => ({ url: `/crm/promotions/${id}/revoke`, method: 'POST' }),
};
