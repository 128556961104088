import { useState } from 'react';
import { TEmptyFunction } from 'types/common';

const useForceRefreshData = () => {
	const [forceRefreshData, setRefreshData] = useState(0);

	const refreshingData: TEmptyFunction = () => setRefreshData((previousValue) => previousValue + 1);

	return { forceRefreshData, refreshingData };
};

export { useForceRefreshData };
